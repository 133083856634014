import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				Noleggio di yacht Kateryna
			</title>
			<meta name={"description"} content={"La nostra promessa è una miscela perfetta di comfort e scoperta, creata per coloro che cercano di abbandonarsi alla bellezza della distesa dell'oceano. "} />
			<meta property={"og:title"} content={"Noleggio di yacht Kateryna"} />
			<meta property={"og:description"} content={"La nostra promessa è una miscela perfetta di comfort e scoperta, creata per coloro che cercano di abbandonarsi alla bellezza della distesa dell'oceano. "} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
		</Helmet>
		<Components.Header>
			<Override slot="link3" />
		</Components.Header>
		<Components.Hero>
			<Override slot="text">
				FAQ
			</Override>
			<Override slot="text1">
				Mentre pianifichi il percorso per un'escursione Noleggio di yacht Kateryna, le domande potrebbero emergere come isole all'orizzonte. La nostra sezione FAQ è la tua bussola, che ti guida attraverso le domande più comuni per garantire che la pianificazione del tuo viaggio sia fluida come navigare su un mare calmo.
			</Override>
			<Override slot="link" href="/index">
				Vai alla pagina principale
			</Override>
			<Override
				slot="image"
				src="https://images.unsplash.com/photo-1600177897995-e67c73ed44e9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
				object-fit="cover"
				object-position="bottom"
				border-radius="30px"
				box-shadow="--xl"
			/>
		</Components.Hero>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="--headline3" color="--darkL1">
							Quali tipi di yacht sono disponibili per il noleggio?
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="#555a5f">
							Flotta diversificata: offriamo una gamma di yacht, dagli eleganti monoscafi agli spaziosi catamarani, ciascuno attrezzato per lusso, sicurezza e prestazioni.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="--headline3" color="--darkL1">
							Come posso personalizzare il mio itinerario?
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="#555a5f">
							Il tuo viaggio, a modo tuo: il nostro team lavora con te per creare un itinerario adatto ai tuoi desideri di viaggio, dalla durata alle destinazioni.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="--headline3" color="--darkL1">
							Ho bisogno di esperienza di navigazione per noleggiare uno yacht?
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="#555a5f">
							Equipaggi qualificati al timone: non è necessaria alcuna esperienza di navigazione. I nostri yacht sono dotati di capitani esperti per navigare nel tuo viaggio.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="--headline3" color="--darkL1">
							Cosa devo mettere in valigia per la mia escursione in yacht?
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="#555a5f">
							Elementi essenziali per il mare: consigliamo abbigliamento comodo, costume da bagno, protezione solare e senso di avventura! L'elenco dettagliato verrà fornito al momento della prenotazione.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="--headline3" color="--darkL1">
							I pasti sono compresi nel noleggio?
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="#555a5f">
							Cucina Gourmet Seascape: Sì, i nostri charter includono i pasti. I nostri chef di bordo preparano squisiti menu su misura per le tue preferenze.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" font="--headline3" color="--darkL1">
							È possibile soddisfare esigenze dietetiche particolari?
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="#555a5f">
							Pranzo personalizzato: assolutamente. Informaci delle tue esigenze dietetiche e i nostri chef garantiranno che la tua esperienza culinaria sia sicura e deliziosa.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6606aeb30072400020ebce37"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});